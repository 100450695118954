import { useTexture } from "@react-three/drei";
import BlockGrass from "./textures/block_grass.jpg";
import useTextureClone from "../hooks/useTextureClone";

const GrassFloor = () => {
  const grassTexture = useTexture(BlockGrass);
  const grassTextureWrap = useTextureClone(grassTexture, undefined, 20);

  return (
    <mesh receiveShadow>
      <planeGeometry args={[100, 100]} />
      <meshPhongMaterial map={grassTextureWrap} flatShading />
    </mesh>
  );
};
export default GrassFloor;
