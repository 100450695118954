import { Group, LinearMipMapLinearFilter, NearestFilter, sRGBEncoding, Texture } from "three";
import React, { useEffect, useRef, useState } from "react";
import { meshBounds, useTexture } from "@react-three/drei";

import ChestLock from "./textures/chest_lock.png";
import ChestTopDouble from "./textures/chest_top_double.png";
import ChestCoverSide from "./textures/chest_cover_side.png";
import ChestCoverSideDouble from "./textures/chest_cover_side_double.png";

import ChestBoxSide from "./textures/chest_box_side.png";
import ChestBoxSideDouble from "./textures/chest_box_side_double.png";
import { ThreeEvent, useFrame } from "@react-three/fiber";
import ChestOpeningMP3 from "./sounds/chest_open.mp3";
import useSound from "use-sound";

const FRAME_COLOR = "#333333",
  CUBE_SIZE = 5,
  DOUBLE_SIZE = 10,
  COVER_SIZE = 1.5,
  BOX_SIZE = 3.5;

const ChestLockPart = ({ x, y, z }: { x: number; y: number; z: number }) => {
  const chestLock = useTexture(ChestLock);

  return (
    <mesh position={[x, y, z]} receiveShadow castShadow>
      <boxBufferGeometry args={[0.6, 0.5, 1.2]} />
      <meshLambertMaterial
        map={chestLock}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
      />
    </mesh>
  );
};

const ChestWall = ({
  x,
  y,
  z,
  width,
  height,
  map,
  rotation,
}: {
  x: number;
  y: number;
  z?: number | undefined;
  width: number;
  height: number;
  map: Texture;
  rotation: number[];
}) => {
  return (
    <mesh
      position={[x, y, typeof z === "number" ? z : height / 2]}
      receiveShadow
      castShadow
      rotation={rotation ? [rotation[0], rotation[1], rotation[2]] : undefined}
    >
      <boxBufferGeometry args={[0.2, height, width]} />
      <meshPhongMaterial
        map={map}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={map}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial color={FRAME_COLOR} attachArray="material" flatShading />
      <meshPhongMaterial color={FRAME_COLOR} attachArray="material" flatShading />
      <meshPhongMaterial color={FRAME_COLOR} attachArray="material" flatShading />
      <meshPhongMaterial color={FRAME_COLOR} attachArray="material" flatShading />
    </mesh>
  );
};

const ChestCover = ({ open = false, amountOfLocks = 1 }) => {
  const ref = useRef<Group | null>(null);
  const [opening, setOpening] = useState<number>(0);
  const [playChestOpen] = useSound(ChestOpeningMP3);

  const lockDistance = DOUBLE_SIZE / (amountOfLocks + 1);
  const locks = [];
  for (let x = -CUBE_SIZE + lockDistance, i = 0; i < amountOfLocks; i++, x += lockDistance) {
    locks.push(<ChestLockPart key={i} x={x} y={-CUBE_SIZE / 2 - 0.25} z={0} />);
  }
  useFrame(() => {
    if (!ref.current || !opening) return;
    const group = ref.current;
    if ((opening < 0 && group.rotation.x >= 0) || (opening > 0 && group.rotation.x <= -Math.PI / 4)) {
      setOpening(0);
      return;
    }
    group.rotation.set(group.rotation.x - opening, 0, 0);
  });
  useEffect(() => {
    if (open) {
      setOpening(Math.PI / 80);
      playChestOpen();
    } else setOpening(-Math.PI / 80);
  }, [open, playChestOpen]);

  const chestCoverSide = useTexture(ChestCoverSide);
  const chestCoverSideDouble = useTexture(ChestCoverSideDouble);
  const chestTopDouble = useTexture(ChestTopDouble);

  return (
    <group ref={ref} position={[0, CUBE_SIZE / 2, BOX_SIZE]}>
      <group position={[0, -CUBE_SIZE / 2, 0]}>
        <ChestWall
          map={chestCoverSide}
          width={CUBE_SIZE}
          height={COVER_SIZE}
          x={DOUBLE_SIZE / 2 - 0.1}
          y={0}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <ChestWall
          map={chestCoverSide}
          width={CUBE_SIZE}
          height={COVER_SIZE}
          x={-DOUBLE_SIZE / 2 + 0.1}
          y={0}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <ChestWall
          map={chestTopDouble}
          width={DOUBLE_SIZE}
          height={CUBE_SIZE}
          x={0}
          y={0}
          z={COVER_SIZE - 0.1}
          rotation={[Math.PI / 2, Math.PI / 2, Math.PI / 2]}
        />
        <ChestWall
          map={chestCoverSideDouble}
          width={DOUBLE_SIZE}
          height={COVER_SIZE}
          x={0}
          y={CUBE_SIZE / 2}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
        <ChestWall
          map={chestCoverSideDouble}
          width={DOUBLE_SIZE}
          height={COVER_SIZE}
          x={0}
          y={-CUBE_SIZE / 2}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
        />
        {locks}
      </group>
    </group>
  );
};

const ChestBox = () => {
  const chestBoxSide = useTexture(ChestBoxSide);
  const chestBoxSideDouble = useTexture(ChestBoxSideDouble);
  const chestTopDouble = useTexture(ChestTopDouble);
  return (
    <group>
      {/* side right */}
      <ChestWall
        map={chestBoxSide}
        width={CUBE_SIZE}
        height={BOX_SIZE}
        x={DOUBLE_SIZE / 2 - 0.1}
        y={0}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* side left */}
      <ChestWall
        map={chestBoxSide}
        width={CUBE_SIZE}
        height={BOX_SIZE}
        x={-DOUBLE_SIZE / 2 + 0.1}
        y={0}
        rotation={[Math.PI / 2, 0, 0]}
      />
      {/* box bottom */}
      <ChestWall
        map={chestTopDouble}
        width={DOUBLE_SIZE}
        height={CUBE_SIZE}
        x={0}
        y={0}
        z={0.1}
        rotation={[Math.PI / 2, Math.PI / 2, Math.PI / 2]}
      />
      {/* box back */}
      <ChestWall
        map={chestBoxSideDouble}
        width={DOUBLE_SIZE}
        height={BOX_SIZE}
        x={0}
        y={CUBE_SIZE / 2}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
      />
      {/* box front */}
      <ChestWall
        map={chestBoxSideDouble}
        width={DOUBLE_SIZE}
        height={BOX_SIZE}
        x={0}
        y={-CUBE_SIZE / 2}
        rotation={[Math.PI / 2, Math.PI / 2, 0]}
      />
    </group>
  );
};

const MinecraftChest = ({
  amountOfLocks = 1,
  open = false,
  onClick = null as unknown as (event: ThreeEvent<PointerEvent>) => void,
}) => {
  return (
    <group raycast={meshBounds} onPointerUp={onClick}>
      <ChestCover open={open} amountOfLocks={amountOfLocks} />
      <ChestBox />
    </group>
  );
};

export default MinecraftChest;
