import { Backdrop, Fab } from "@material-ui/core";
import { VolumeUp } from "@material-ui/icons";
import MinecraftButton from "./MinecraftButton";
import WelcomeMP3 from "./sounds/welcome.mp3";
import useSound from "use-sound";

const Welcome = ({ open, onClick }: { open: boolean; onClick: Function }) => {
  const [playWelcome] = useSound(WelcomeMP3);
  return (
    <Backdrop open={open} sx={{ zIndex: 3, flexDirection: "column" }}>
      <Fab onClick={() => playWelcome()}>
        <VolumeUp />
      </Fab>
      <div style={{ color: "white", fontSize: "2rem", margin: "20px", textAlign: "center" }}>
        <h2>היום הולדת של ליאב</h2>
        <br />
        ברוכים הבאים לעולם המיינקראפט
        <br />
        אנחנו מוכנים למצוא את תיבת האוצר
        <br />
        מוכנים ילדים?
        <br />
        <br />
        <br />
        אז בואו נצא לדרך
      </div>

      <MinecraftButton onClick={() => onClick()}>התחילו!</MinecraftButton>
    </Backdrop>
  );
};

export default Welcome;
