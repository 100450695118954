import { Slide, Dialog, DialogContent, styled, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import useSound from "use-sound";
import WrongCodeMP3 from "./sounds/wrong_code.mp3";
import RightCodeMP3 from "./sounds/success.mp3";
import LockImage from "./LockImage";
import MinecraftButton from "./MinecraftButton";
import { Backspace, Clear } from "@material-ui/icons";

const Workspace = styled("div")({
  borderTop: "2px solid #404040",
  borderLeft: "2px solid #404040",
  borderBottom: "2px solid silver",
  borderRight: "2px solid silver",
  background: "#808080",
  margin: "1rem 0",
  padding: "1rem",
  textAlign: "center",
  direction: "ltr",
  "& *": {
    direction: "ltr",
  },
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "& button": {
    flexBasis: "25%",
    minWidth: "3.3rem",
  },
  "&[data-invalid]": {
    background: "#800000",
  },
});

const formatAnswer = (value: string, answer: string) =>
  (value + "_".repeat(answer.length)).substr(0, answer.length).split("").join(" ");

const EnterCodeModal = ({
  open,
  color,
  answer,
  onClose,
  onSuccess,
}: {
  open: boolean;
  color: string;
  answer: string;
  onClose: (event?: MouseEvent) => void;
  onSuccess: Function;
}) => {
  const [checking, setChecking] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [code, setCode] = useState("");
  const [playRight] = useSound(RightCodeMP3);
  const [playWrong] = useSound(WrongCodeMP3);

  const check = () => {
    setInvalid(false);
    setChecking(true);
    setTimeout(() => {
      if (code === answer) {
        setInvalid(false);
        playRight();
        setChecking(false);
        onClose();
        onSuccess();
      } else {
        setInvalid(true);
        playWrong();
        setChecking(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (open) {
      setInvalid(false);
      setChecking(false);
      setCode("");
    }
  }, [open]);

  const addDigit = (e: any) => setCode(c => c + e.target.dataset.n);

  return (
    <Dialog
      maxWidth="sm"
      scroll="body"
      fullWidth
      open={open}
      TransitionComponent={Slide}
      keepMounted
      disableEscapeKeyDown
      PaperProps={{ sx: { margin: 0 } }}
    >
      <DialogContent
        sx={{
          borderTop: "2px solid white",
          borderLeft: "2px solid white",
          borderBottom: "2px solid grey",
          borderRight: "2px solid grey",
          background: "#c0c0c0",
        }}
      >
        <div style={{ fontSize: "2.5rem", textAlign: "center" }}>
          הכניסו את הניחוש שלכם <LockImage size={32} color={color} />
        </div>
        <Workspace>
          <MinecraftButton data-n={1} onClick={addDigit} disabled={code.length === answer.length}>
            1
          </MinecraftButton>
          <MinecraftButton data-n={2} onClick={addDigit} disabled={code.length === answer.length}>
            2
          </MinecraftButton>
          <MinecraftButton data-n={3} onClick={addDigit} disabled={code.length === answer.length}>
            3
          </MinecraftButton>
          <MinecraftButton data-n={4} onClick={addDigit} disabled={code.length === answer.length}>
            4
          </MinecraftButton>
          <MinecraftButton data-n={5} onClick={addDigit} disabled={code.length === answer.length}>
            5
          </MinecraftButton>
          <MinecraftButton data-n={6} onClick={addDigit} disabled={code.length === answer.length}>
            6
          </MinecraftButton>
          <MinecraftButton data-n={7} onClick={addDigit} disabled={code.length === answer.length}>
            7
          </MinecraftButton>
          <MinecraftButton data-n={8} onClick={addDigit} disabled={code.length === answer.length}>
            8
          </MinecraftButton>
          <MinecraftButton data-n={9} onClick={addDigit} disabled={code.length === answer.length}>
            9
          </MinecraftButton>
          <MinecraftButton disabled={!code.length} onClick={() => setCode("")}>
            <Clear />
          </MinecraftButton>
          <MinecraftButton data-n={0} onClick={addDigit} disabled={code.length === answer.length}>
            0
          </MinecraftButton>
          <MinecraftButton disabled={!code.length} onClick={() => setCode(x => x.substr(0, x.length - 1))}>
            <Backspace />
          </MinecraftButton>
        </Workspace>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MinecraftButton disabled={checking} onClick={check}>
            {checking ? <CircularProgress size="24px" color="inherit" /> : "בדיקה"}
          </MinecraftButton>
          <Workspace data-invalid={invalid || undefined}>{formatAnswer(code, answer)}</Workspace>
          <MinecraftButton onClick={onClose as unknown as any}>ביטול</MinecraftButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EnterCodeModal;
