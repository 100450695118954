import { RepeatWrapping, sRGBEncoding, Texture, Vector2 } from "three";
import { useMemo } from "react";

const useTextureClone = (texture: Texture, rotation?: number, wrap?: number) =>
  useMemo(() => {
    const x = texture.clone();
    x.encoding = sRGBEncoding;
    x.center.set(0.5, 0.5);
    if (rotation) x.rotation = rotation;
    if (wrap) {
      x.wrapS = RepeatWrapping;
      x.wrapT = RepeatWrapping;
      x.repeat = new Vector2(wrap, wrap);
      x.anisotropy = 10;
    }
    x.needsUpdate = true;
    return x;
  }, [texture, rotation, wrap]);

export default useTextureClone;
