import MaterialUIProvider from "./MaterialUIProvider";
import MinecraftIndex from "./minecraft/MinecarftIndex";

function App() {
  return (
    <MaterialUIProvider>
      <MinecraftIndex />
    </MaterialUIProvider>
  );
}

export default App;
