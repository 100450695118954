const Lock = (props: any) => {
  return (
    <group {...props}>
      <mesh receiveShadow castShadow rotation={[Math.PI / 2, 0, 0]}>
        <torusBufferGeometry args={[0.3, 0.1, 8, 16]} />
        <meshLambertMaterial color={"#808080"} />
      </mesh>
      <mesh receiveShadow castShadow position={[0, 0, -0.35]}>
        <boxGeometry args={[0.8, 0.3, 0.6]} />
        <meshLambertMaterial color={props.color || "#ff0000"} />
      </mesh>
    </group>
  );
};

export default Lock;
