import useSound from "use-sound";
import TipusMP3 from "./sounds/tipus.mp3";
import { Fab } from "@material-ui/core";
import { VolumeUp } from "@material-ui/icons";
import HazirMP3 from "./sounds/hazir.mp3";
import LightMP3 from "./sounds/light.mp3";
import FinishedMP3 from "./sounds/finished.mp3";

const hints = [
  { Component: () => <span>הרכיבו את ה🧩 בשביל להשיג את ה🔑</span>, answer: "17072014" },
  {
    Component: () => {
      const [playTipus] = useSound(TipusMP3);
      return (
        <span>
          <Fab onClick={() => playTipus()}>
            <VolumeUp />
          </Fab>
          <br />
          חפשו את הצורות והתאימו. בשביל למצוא את הצורות אתם צריכים לטפס!
        </span>
      );
    },
    answer: "73861425",
  },
  {
    Component: () => {
      const [playHazir] = useSound(HazirMP3);
      return (
        <span>
          <Fab onClick={() => playHazir()}>
            <VolumeUp />
          </Fab>
          <br />
          <br />
          חברו את החזיר וקבלו את הרמז הבא
        </span>
      );
    },
    answer: "8472",
  },
  {
    Component: () => {
      const [playLight] = useSound(LightMP3);
      return (
        <span>
          <Fab onClick={() => playLight()}>
            <VolumeUp />
          </Fab>
          <br />
          <br />
          את הרמז הבא אתם לא יכולים למצוא באור
        </span>
      );
    },
    answer: "1473",
  },
  {},
  {
    Component: () => {
      const [playFinished] = useSound(FinishedMP3);
      return (
        <span>
          <Fab onClick={() => playFinished()}>
            <VolumeUp />
          </Fab>
          <br />
          <br />
          סיימתם את המשימה, האוצר נמצא במקום חם מאוד מאוד!
        </span>
      );
    },
  },
];

export default hints;
