import { MutableRefObject, useCallback, useEffect, useState } from "react";

/* View in fullscreen */
function openFullscreen(elem: any) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

/* Close fullscreen */
function closeFullscreen() {
  const doc = document as unknown as any;
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    /* Firefox */
    return doc.mozCancelFullScreen();
  } else if (doc.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    return doc.webkitExitFullscreen();
  } else if (doc.msExitFullscreen) {
    /* IE/Edge */
    return doc.msExitFullscreen();
  }
}

const useFullscreen = (ref?: MutableRefObject<HTMLElement | null>) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    if (isFullscreen) {
      closeFullscreen();
      setIsFullscreen(false);
    } else {
      openFullscreen(ref ? ref.current : document.body);
      setIsFullscreen(true);
    }
  };
  const handleExitFullscreen = useCallback(
    () => isFullscreen && !document.fullscreenElement && setIsFullscreen(false),
    [isFullscreen, setIsFullscreen],
  );
  useEffect(() => {
    document.addEventListener("fullscreenchange", handleExitFullscreen, false);
    return () => document.removeEventListener("fullscreenchange", handleExitFullscreen, false);
  }, [handleExitFullscreen]);

  return [isFullscreen, toggleFullscreen];
};

export default useFullscreen;
