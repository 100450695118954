import BlockGrass from "./textures/block_grass.jpg";
import BlockSide from "./textures/block_side.jpg";
import BlockBottom from "./textures/block_bottom.jpg";
import { useTexture } from "@react-three/drei";
import { LinearMipMapLinearFilter, NearestFilter, sRGBEncoding } from "three";
import useTextureClone from "../hooks/useTextureClone";

const Block = (props: any) => {
  const dirt: boolean = props.dirt;
  const blockGrass = useTexture(BlockGrass);
  const blockSide = useTexture(BlockSide);
  const blockSide2 = useTextureClone(blockSide, Math.PI / 2);
  const blockSide3 = useTextureClone(blockSide, (Math.PI * 3) / 2);
  const blockSide4 = useTextureClone(blockSide, Math.PI);
  const blockBottom = useTexture(BlockBottom);
  return (
    <mesh receiveShadow castShadow {...props}>
      <boxBufferGeometry args={[5, 5, 5]} />
      <meshPhongMaterial
        map={dirt ? blockBottom : blockSide2}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={dirt ? blockBottom : blockSide3}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={dirt ? blockBottom : blockSide4}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={dirt ? blockBottom : blockSide}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={dirt ? blockBottom : blockGrass}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
      <meshPhongMaterial
        map={blockBottom}
        map-encoding={sRGBEncoding}
        map-magFilter={NearestFilter}
        map-minFilter={LinearMipMapLinearFilter}
        attachArray="material"
        flatShading
      />
    </mesh>
  );
};

export default Block;
