import { Props } from "@react-three/fiber";
import { Vector3 } from "three";
import CameraControls from "../CameraControls";
import { useState } from "react";
import GrassFloor from "./GrassFloor";
import MinecraftChest from "./MinecraftChest";
import Block from "./Block";
import Lock from "./Lock";
import { softShadows, AdaptiveDpr, AdaptiveEvents } from "@react-three/drei";

softShadows();

const canvasProps: Props = {
  shadows: true,
  camera: { fov: 50, near: 1, far: 1000, position: new Vector3(8, -30, 13), up: new Vector3(0, 0, 1) },
  children: false, // to be overridden
};

const MinecraftScene = ({ lock, onChestOpen }: { lock: number; onChestOpen: Function }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AdaptiveDpr pixelated />
      <AdaptiveEvents />
      <fog attach="fog" args={["#4f9bf8", 50, 1000]} />
      <CameraControls minDistance={15} maxDistance={30} enablePan={false} />
      <pointLight args={["#ffffff", 0.66]} position={[0, -20, 40]} />
      <directionalLight
        args={[0xffffff, 1]}
        position={[-10, -20, 10]}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={80}
        shadow-camera-left={-20}
        shadow-camera-right={20}
        shadow-camera-top={20}
        shadow-camera-bottom={-20}
      />

      <GrassFloor />

      <Block position={[-15, -10, 2.5]} />
      <Block position={[-15, -15, 2.5]} />
      <Block position={[-20, -10, 7.5]} />
      <Block position={[-20, -15, 7.5]} />

      <Block position={[-15, -5, 7.5]} />
      <Block position={[-15, 0, 7.5]} />
      <Block position={[-15, 5, 7.5]} />
      <Block position={[-15, 10, 7.5]} />

      <Block position={[-10, -5, 2.5]} />
      <Block position={[-10, 0, 2.5]} />
      <Block position={[-10, 5, 2.5]} />
      <Block position={[-10, 10, 2.5]} />

      <Block position={[10, -5, 2.5]} />
      <Block position={[10, 0, 2.5]} />
      <Block position={[10, 5, 2.5]} />
      <Block position={[10, 10, 2.5]} />

      <Block position={[15, -5, 2.5]} dirt />
      <Block position={[15, -5, 7.5]} />
      <Block position={[15, 0, 7.5]} />
      <Block position={[15, 5, 7.5]} />
      <Block position={[15, 10, 7.5]} />

      <Block position={[-10, 10, 2.5]} />
      <Block position={[-5, 10, 2.5]} />
      <Block position={[0, 10, 2.5]} />
      <Block position={[5, 10, 2.5]} />
      <Block position={[10, 10, 2.5]} />

      <Block position={[-10, 15, 7.5]} />
      <Block position={[-5, 15, 7.5]} />
      <Block position={[0, 15, 7.5]} />
      <Block position={[5, 15, 7.5]} />
      <Block position={[10, 15, 7.5]} />

      <MinecraftChest
        amountOfLocks={4}
        open={open}
        onClick={
          lock > 3
            ? () => {
                setOpen(true);
                onChestOpen();
              }
            : undefined
        }
      />
      {lock < 1 && <Lock position={[-3, -3, 2.6]} color={"#ff0000"} />}
      {lock < 2 && <Lock position={[-1, -3, 2.6]} color={"#00ff00"} />}
      {lock < 3 && <Lock position={[1, -3, 2.6]} color={"#0000ff"} />}
      {lock < 4 && <Lock position={[3, -3, 2.6]} color={"#ffff00"} />}
    </>
  );
};

MinecraftScene.canvasProps = canvasProps;

export default MinecraftScene;
