import { useMemo } from "react";
import { colors, createMuiTheme, CssBaseline, GlobalStyles, ThemeProvider } from "@material-ui/core";

const overrides = {
  "*": {
    direction: "rtl",
  },
  "#root": {
    height: "100vh",
    display: "flex",
  },
  body: {
    font: `1.5rem monospace, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols`,
    fontWeight: 700,
  },
  html: {
    fontSize: "16px",
    "@media screen and (max-width:1024px)": {
      fontSize: "10px",
    },
  },
};

const MaterialUIProvider = ({ children }: { children: any }) => {
  const theme = useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: { main: colors.grey[800] },
        secondary: { main: colors.red[700] },
      },
      components: {
        MuiFab: {
          styleOverrides: {
            root: {
              width: "4rem",
              height: "4rem",
            },
          },
        },
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={overrides as unknown as any} />
      {children}
    </ThemeProvider>
  );
};

export default MaterialUIProvider;
