import { styled } from "@material-ui/core";

const MinecraftButton = styled("button")({
  font: `1.5rem monospace, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols`,
  fontWeight: 700,
  minWidth: "6rem",
  minHeight: "3.5rem",
  margin: "8px",
  padding: "10px",
  borderTop: "2px solid white",
  borderLeft: "2px solid white",
  borderBottom: "2px solid grey",
  borderRight: "2px solid grey",
  background: "#c0c0c0",
  "&:active": {
    borderTop: "2px solid #404040",
    borderLeft: "2px solid #404040",
    borderBottom: "2px solid silver",
    borderRight: "2px solid silver",
    background: "#808080",
  },
  "&:disabled": {
    color: "grey",
  },
});

export default MinecraftButton;
