const LockImage = ({ size = 0, color = "#ff0000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.4385 16.4385"
      width={size || undefined}
      height={size || undefined}
    >
      <g>
        <path
          d="M3.7312 6.872h9.0877a1.323 1.323 0 011.323 1.3229v6.7832a1.323 1.323 0 01-1.323 1.323H3.7312a1.323 1.323 0 01-1.3229-1.323V8.1949a1.323 1.323 0 011.323-1.323z"
          fill={color}
        />
        <path d="M3.8898 4.9244c.0786-1.7281.1925-2.1432.811-2.954C5.6291.7533 6.7738.1664 8.2193.1664s2.5902.5869 3.5184 1.8038c.6185.811.7325 1.226.811 2.9541l.0914 2.0106c-.507-.0695-1.803.1937-1.8775-.0946-.0242-.1508.0087-.5496.0252-.9007.1187-2.5342-.8912-3.8849-2.5685-3.8849-1.6774 0-2.5686 1.348-2.5686 3.8849 0 .6115.086.9315-.1383 1.0225-.588-.036-1.0852-.0272-1.714-.0272z" />
      </g>
    </svg>
  );
};
export default LockImage;
