import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import React from "react";

const CameraControls = (props: any) => {
  const { camera } = useThree();

  // @ts-ignore
  window.__camera = camera;

  return <OrbitControls camera={camera} maxPolarAngle={Math.PI * 0.5} minDistance={1} maxDistance={100} {...props} />;
};

export default CameraControls;
