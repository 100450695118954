import { Suspense, useEffect, useMemo, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Preload, Stats } from "@react-three/drei";
import { Fab, styled } from "@material-ui/core";
import MinecraftScene from "./MinecraftScene";
import EnterCodeModal from "./EnterCodeModal";
import LockImage from "./LockImage";
import { Fullscreen, FullscreenExit, Lightbulb } from "@material-ui/icons";
import useSound from "use-sound";
import TadaMP3 from "./sounds/tada.mp3";
import hints from "./hints";
import Welcome from "./Welcome";
import useFullscreen from "../hooks/useFullscreen";

const SceneContainer = styled("div")({
  background: "linear-gradient(to bottom, #1b75ba , #04f6ff 60%)",
  flex: 1,
  display: "flex",
});

const Toolbar = styled("div")({
  position: "absolute",
  background: "rgba(192,192,192,0.5)",
  borderTop: "2px solid silver",
  borderLeft: "2px solid silver",
  borderBottom: "2px solid grey",
  borderRight: "2px solid grey",
  bottom: "1vh",
  width: "50vw",
  height: "5vw",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  display: "flex",
  direction: "ltr",
});

const ToolStyle = {
  borderTop: "2px solid grey",
  borderLeft: "2px solid grey",
  borderBottom: "2px solid silver",
  borderRight: "2px solid silver",
  background: "rgba(0,0,0,0.4)",

  margin: "0.2vw",
  width: "5vw",
  cursor: "pointer",
  boxShadow: "0 0 5px 5px white",
};

const InactiveToolStyle = {
  borderTop: "2px solid grey",
  borderLeft: "2px solid grey",
  borderBottom: "2px solid silver",
  borderRight: "2px solid silver",
  background: "rgba(0,0,0,0.7)",
  margin: "0.2vw",
  width: "5vw",
  opacity: 0.5,
};

const Hint = styled("div")({
  fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  position: "absolute",
  fontSize: "2rem",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "20rem",
  height: "20rem",
  right: "-20rem",
  top: "6rem",
  padding: "1vw",
  transition: "opacity 2s ease-in-out, right 1s ease-in-out",
  borderRadius: "10px",
  zIndex: 2,
  "&:empty": {
    opacity: 0,
  },
  "&[data-open]": {
    right: "1rem",
  },
  "&[data-color='0']": {
    background: "radial-gradient(rgba(255,255,255,0.9), rgba(255,0,0,0.8))",
  },
  "&[data-color='1']": {
    background: "radial-gradient(rgba(255,255,255,0.9), rgba(0,255,0,0.8))",
  },
  "&[data-color='2']": {
    background: "radial-gradient(rgba(255,255,255,0.9), rgba(0,0,255,0.8))",
  },
  "&[data-color='3']": {
    background: "radial-gradient(rgba(255,255,255,0.9), rgba(255,255,0,0.8))",
  },
  "&[data-color='5']": {
    background: "radial-gradient(rgba(255,255,255,0.9), rgba(192,192,128,0.8))",
  },
});

const lockColor = ["#ff0000", "#00ff00", "#0000ff", "#ffff00"];

const MinecraftIndex = ({ children }: { children?: any }) => {
  const [selectedLock, setSelectedLock] = useState(-1);
  const [welcome, setWelcome] = useState(true);
  const [lock, setLock] = useState(0);
  const [hint, setHint] = useState(false);
  const [playTada] = useSound(TadaMP3);
  const [isFullscreen, toggleFullscreen] = useFullscreen();
  const HintComponent = hints[lock]?.Component;

  const stats = useMemo(() => {
    const sp = new URLSearchParams(window.location.search);
    return sp.has("stats");
  }, []);

  useEffect(() => {
    if (lock === 4) {
      setTimeout(() => {
        playTada();
      }, 2000);
    }
  }, [lock, playTada]);

  return (
    <SceneContainer>
      <Welcome onClick={() => setWelcome(false)} open={welcome} />
      <Fab
        color="primary"
        sx={{ position: "absolute", top: "1vw", right: "1vw", zIndex: 2 }}
        onClick={() => setHint(h => !h)}
      >
        <Lightbulb />
      </Fab>
      <Fab
        color="primary"
        sx={{ position: "absolute", bottom: "1vw", right: "1vw", zIndex: 9 }}
        onClick={toggleFullscreen as unknown as any}
      >
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      </Fab>
      <Hint data-color={lock} data-open={hint || undefined}>
        {HintComponent && <HintComponent />}
      </Hint>
      <EnterCodeModal
        open={selectedLock > -1}
        answer={hints[lock]?.answer || ""}
        color={lockColor[lock]}
        onClose={() => setSelectedLock(-1)}
        onSuccess={() => setLock(l => l + 1)}
      />

      {!welcome && (
        <Toolbar>
          <div
            style={lock !== 0 ? InactiveToolStyle : ToolStyle}
            onClick={lock !== 0 ? undefined : () => setSelectedLock(0)}
          >
            {lock < 1 && <LockImage color={lockColor[0]} />}
          </div>
          <div
            style={lock !== 1 ? InactiveToolStyle : ToolStyle}
            onClick={lock !== 1 ? undefined : () => setSelectedLock(1)}
          >
            {lock < 2 && <LockImage color={lockColor[1]} />}
          </div>
          <div
            style={lock !== 2 ? InactiveToolStyle : ToolStyle}
            onClick={lock !== 2 ? undefined : () => setSelectedLock(2)}
          >
            {lock < 3 && <LockImage color={lockColor[2]} />}
          </div>
          <div
            style={lock !== 3 ? InactiveToolStyle : ToolStyle}
            onClick={lock !== 3 ? undefined : () => setSelectedLock(3)}
          >
            {lock < 4 && <LockImage color={lockColor[3]} />}
          </div>
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
          <div style={InactiveToolStyle} />
        </Toolbar>
      )}
      <Canvas {...MinecraftScene.canvasProps}>
        {children}
        {stats && <Stats />}
        <Suspense fallback={null}>
          <Preload all />
          <MinecraftScene
            lock={lock}
            onChestOpen={() => {
              setLock(5);
              setHint(true);
            }}
          />
        </Suspense>
      </Canvas>
    </SceneContainer>
  );
};

export default MinecraftIndex;
